import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Input from "../../../Input";
import Select from "../../../Select";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { callGetIngredients } from "../../../../api/recipe";
import dragIcon from "../../../../assets/images/common/drag.svg";
import whitePlus from "../../../../assets/images/common/plus-circle.png";
import blackPlus from "../../../../assets/images/common/plus-circle-outline.svg";
import { callDeleteFDCIngredient } from "../../../../api/recipe";
import { ShoppingListUnitsEnum } from '../../../../utils/shoppingList';
import { toast } from "react-toastify";
import { createEmptyIngredient } from "../..";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { callCreateIngredientForm } from "../../../../api/enum";

class Ingredients extends Component {
  state = {
    isModalVisible: false,
    searchValue: '',
    fetchedIngredients: [],
    pageCount: 0,
    currentPage: 0,
    dataType: "",
    manageModalVisible: false,
    isAddIngredientModalVisible: false,
    isFormEditingVisible: false,
    isIngredientEditingVisible: false,
    formModalValue: undefined,
    ingredientModalValue: undefined,
    isHeading: false,
    newFormName: '',
    formName: '',
    ingredientName: '',
    ingredientIndex: -1,
    isAddHeadingVisible: false,
    headingName: ''
  };

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleFormEditInputChange = this.handleFormEditInputChange.bind(this);
    this.handleIngredientEditInputChange = this.handleIngredientEditInputChange.bind(this);
    this.editIngredientForm = this.editIngredientForm.bind(this);
    this.editIngredient = this.editIngredient.bind(this);
    this.handleHeadingChange = this.handleHeadingChange.bind(this);
    this.debounceTimeout = null;
    this.cancelTokenSource = null;
  } 

  openManageModal = () => {
    this.setState({
      manageModalVisible: true,
    });
  };

  closeManageModal = () => {
    this.setState({
      manageModalVisible: false,
    });
  };

  openModal = (heading) => {
    this.setState({
      isModalVisible: true,
      isHeading: heading
    })
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
      fetchedIngredients: [],
      pageCount: 0,
      currentPage: 0,
      isHeading: false,
    })
  };

  getIngredients = async (search, page, signal) => {
    try {
      const response = await callGetIngredients(search || this.state.searchValue, page, this.state.dataType, signal);

      this.setState({
        fetchedIngredients: response.data.ingredients,
        pageCount: response.data.pages,
      });
    } catch (err) {
      if (err.code === undefined) return;
      toast.error('Get ingredients server error');
    }
  };

  handleCreateForm = async () => {
    try {
      const response = await callCreateIngredientForm(this.state.newFormName);
      this.setState({manageModalVisible: false});
      this.props.onIngredientFormCreate(response.data);
      this.props.changeIngredient(response.data.name, this.state.ingredientIndex, "form");
    } catch (error) {
      if (error.code === 409) {
        toast.error('Form with provided name already exists');
      }
    }
  }

  handleIngredientChoose = async () => {
    if (this.props.list.find(el => el.name.name === this.state.searchValue)) {
      toast.error('Ingredient with provided name already exists');
      return;
    }
    this.setState({
      openedIndex: null,
      searchValue: '',
    });
    const isHeading = this.state.isHeading;
    this.closeModal();

    try {
      const emptyIngredient = createEmptyIngredient(false);
      emptyIngredient.name.nutrition = {
        calories: 0,
        carbs: 0,
        protein: 0,
        fat: 0,
        servingSize: 1,
        servingUnit: '',
        measurementUnits: [],
      }
      emptyIngredient.name.name = this.state.searchValue;
      emptyIngredient.name.description = this.state.searchValue;
      emptyIngredient.isNew = true;
      emptyIngredient.heading = isHeading;

      this.props.addIngredient(false, emptyIngredient);
      // this.props.addIngredient(false)

      // this.props.changeIngredient(
      //   {
      //     name: ingredient.name.name || ingredient.description,
      //     fdcId: ingredient.fdcId,
      //     description: ingredient.description,
      //     nutrition: nutrition.data
      //   },
      //   ingredientIndex,
      //   'name'
      // );
      // this.props.changeIngredient({ target: { value: nutrition.data.measurementUnits.length > 1 ? nutrition.data.measurementUnits[1].unit : nutrition.data.servingUnit } }, ingredientIndex, "measure");
    } catch (e) {
      console.log('e: ', e);
      toast.error(e.code === 404 ? 'Not found' : 'Get ingredient server error. Please try again');
    }
  };

  changeSearchValue = e => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleInputChange(event) {
    const value = event.target.value;
    this.setState({ searchValue: value });
  }

  handleFormInputChange(event) {
    const value = event.target.value;
    this.setState({ newFormName: value });
  }

  handleFormEditInputChange(event) {
    const value = event.target.value;
    this.setState({ formName: value });
  }
  handleIngredientEditInputChange(event) {
    const value = event.target.value;
    this.setState({ ingredientName: value });
  }

  editIngredientForm() {
    this.props.editIngredientForm(this.state.formModalValue, this.state.formName);
    this.setState({ isFormEditingVisible: false, formModalValue: undefined, formName: '', ingredientIndex: -1 });
    this.props.changeIngredient(this.state.formName, this.state.ingredientIndex, "form");
  }

  editIngredient() {
    this.props.editIngredient(this.state.ingredientModalValue, this.state.ingredientName);
    this.setState({ isIngredientEditingVisible: false, ingredientModalValue: undefined, ingredientName: '', ingredientIndex: -1 });
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Component unmounted.');
    }
  }

  onSearchKeyPress = e => {

    if (e.key === 'Enter') {
      this.setState({ currentPage: 0 });
      this.getIngredients(e.target.value, 1);
    }
  };

  onPageChange = ({ selected }) => {
    this.setState({
      currentPage: selected,
    });

    this.getIngredients(null, selected + 1);
  };

  handleHeadingChange = (e) => {
    this.setState({ headingName: e.target.value });
  }

  setDataType = (e) => {

    this.setState({ dataType: e.target.value }, () => {
      if (this.state.searchValue) {
        this.setState({ currentPage: 0 });
        this.getIngredients(this.state.searchValue, 1);
      }
    })
  }
  deleteCustomIngredient = (fdcId) => {
    this.setState({ fetchedIngredients: this.state.fetchedIngredients.filter(i => i.fdcId !== fdcId) }, () => callDeleteFDCIngredient(fdcId).then(success => { }, error => { }).catch(error => { }))
  }
  getHeadingRow = (ingredient, index) => {
    return <Row style={{ marginBottom: "10px" }}  className="row ingredient-row heading">
      <Col md={12}>
        <div className="ingredient-name">
          <div className={`ingredient-options`}>
            <img src={dragIcon} />
          </div>
          <p className="heading-title">{ingredient?.name?.name ?? ''}</p>
          {/* <Input
            placeholder="Enter Heading Title here"
            zIndex={index}
            id={`ingredient-${index}-name`}
            // label={index === 0 && "Ingredient"}
            value={ingredient.name && ingredient.name.name}
            onChange={e => this.props.changeIngredient(
              { name: e.target.value, fdcId: ingredient.name && ingredient.name.fdcId },
              index,
              "name"
            )}
            error={
              this.props.errors && this.props.errors[index] && this.props.errors[index].name
            }
          /> */}
          <div className="delete-button-wrapper">
            {index > 0 && <button onClick={() => this.props.onIngredientDeleted(index, 'heading')} className="delete-button no-translate"></button>}
          </div>
        </div>
      </Col>
    </Row>
  }
  getIngredientRow = (ingredient, index) => {
    const extraOption = this.props.forms.find(el => el.name === ingredient.form) ? {
      value: '',
      label: '',
      disabled: true
    } : {
      value: ingredient.form,
      label: ingredient.form,
      disabled: true
    };

    const extraIngredientOption = this.props.list.find(el => el.name.name === ingredient.name.name) ? {
      value: '',
      label: '',
      disabled: true
    } : {
      value: ingredient.name.name,
      label: ingredient.name.name,
      disabled: true
    };

    return <Row style={{ marginBottom: "10px" }} className={`row ingredient-row ${index !== 0 ? 'white' : ''}`}>
      <Col md={3}>
        <div className="ingredient-name">
          <div className={`ingredient-options`}>
            <img src={dragIcon} />
          </div>
          <DropdownWithSearch
            options={[
              extraIngredientOption,
              ...this.props.list.sort((a,b) => a.name.name.localeCompare(b.name.name)).map(el => ({ value: el.name.name, label: el.name.name, _id: el._id }))
            ].filter(el => el.label)}
            onChange={option => {
              this.props.changeIngredient(
                { name: option.label, fdcId: option.value },
                index,
                "name"
              )
            }}
            defaultSelected={[ingredient.name.name]}
            onAdd={() => this.setState({ isModalVisible: true })}
            error={
              this.props.errors && this.props.errors[index] && this.props.errors[index].name
            }
            onDelete={(option) => this.props.onIngredientDeleteDB(option._id, option.label)}
            onEdit={(option) => this.setState({
              isIngredientEditingVisible: true,
              ingredientModalValue: option._id,
              ingredientName: option.value,
              ingredientIndex: index
            })}
          />
        </div>
      </Col>
      <Col md={3}>
        <DropdownWithSearch
          options={[
            extraOption,
            ...this.props.forms.sort((a,b) => a.name.localeCompare(b.name)).map(type => ({
            value: type.name,
            label: type.name,
            _id: type._id
          }))].filter(el => el.label)}
          defaultSelected={[ingredient.form]}
          onChange={option => this.props.changeIngredient(option.value, index, "form")}
          onAdd={() => this.setState({ manageModalVisible: true, ingredientIndex: index })}
          error={
            this.props.errors && this.props.errors[index] && this.props.errors[index].form
          }
          onEdit={(option) => this.setState({
            isFormEditingVisible: true,
            formModalValue: option._id,
            formName: ingredient.form,
            ingredientIndex: index
          })}
          onDelete={(option) => this.props.deleteIngredientForm(option._id)}
        />
      </Col>
      <Col md={4}>
        <Row>
          <Col md={2}>
            <div>
              <div className="display-label" style={{height: "40px"}}>
                <label>Display:</label>
              </div>
              <div className="display-label" style={{height: "40px"}}>
                <label>Shopping:</label>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div>
              <div className="display-container">
                <Input
                  type="text" 
                  placeholder="Display amount"
                  value={ingredient.displayAmount} 
                  onChange={e => {
                    this.props.changeIngredient(e, index, "displayAmount")
                    this.props.changeIngredient(e, index, "quantity")
                  }}
                  error={this.props.errors && this.props.errors[index] && this.props.errors[index].dipslayQuantity}
                />
              </div>
              <div className="display-container">
                <Input
                  type="text" 
                  name="shoppingAmount"
                  placeholder="Shopping amount"
                  value={ingredient.shoppingList ? ingredient.shoppingList.amount : ""}
                  onChange={e => this.props.changeIngredient(e, index, "shoppingAmount")}
                  error={this.props.errors && this.props.errors[index] && this.props.errors[index].quantity}
                />
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div style={{width: "100%"}}>
              <div className="display-container">
                <Select
                  class="form-select"
                  name="displayUnit"
                  zIndex={index + 4}
                  options={[
                    {label: "Select display unit", value: "" },
                    ...Object.entries(ShoppingListUnitsEnum).map(([key, value]) => ({
                    value: value,
                    label: value
                  })), extraOption].filter(el => el.label)}
                  placeholder="Display"
                  value={ingredient.displayUnit}
                  onChange={e => {
                    this.props.changeIngredient(e, index, "displayUnit")
                    this.props.changeIngredient(e, index, "measure")
                  }}
                />
              </div>
              <div className="display-container">
                <Select
                  class="form-select"
                  name="shoppingUnit"
                  zIndex={index + 4}
                  options={[
                    {label: "Select shopping unit", value: "" },
                    ...Object.entries(ShoppingListUnitsEnum).map(([key, value]) => ({
                    value: value,
                    label: value
                  })), extraOption].filter(el => el.label)}
                  placeholder="Shopping"
                  value={ingredient.shoppingList ? ingredient.shoppingList.unit : ""}
                  onChange={e => this.props.changeIngredient(e, index, "shoppingUnit")}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Select
          zIndex={index + 4}
          options={[{
            value: "",
            label: "Select..."
          }, ...this.props.categories.map(type => ({
            value: type._id,
            label: type.name
          }))]}
          placeholder="Select"
          value={ingredient.category}
          onChange={e => this.props.changeIngredient(e, index, "category")}
          error={
            this.props.errors &&
            this.props.errors[index] &&
            this.props.errors[index].category
          }
        />
        <div className="delete-button-wrapper">
          {index > 0 && <button onClick={() => this.props.onIngredientDeleted(index, 'ingredient')} className="delete-button"></button>}
        </div>
      </Col>
    </Row>;
  }

  render() {

    return (

      <React.Fragment>
        {/* <div className="title-container">
          <div className="title-wrapper">
            <h6 className="page-section">Ingredients</h6>
            <button className="manage-forms-button" onClick={this.openManageModal}>Manage forms</button>
          </div>
        </div> */}
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <Droppable droppableId="rows-container">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Row style={{ marginBottom: "10px" }}  className="row">
                  <Col style={{ fontWeight: 700 }} md={3}>Ingredients</Col>
                  <Col style={{ fontWeight: 700 }} md={3}>Form</Col>
                  <Col style={{ fontWeight: 700 }} md={4}>
                    <Row>
                      <Col style={{ fontWeight: 700 }} md={2}></Col>
                      <Col style={{ fontWeight: 700 }} md={5}>Quantity</Col>
                      <Col style={{ fontWeight: 700 }} md={5}>Unit</Col>
                    </Row>
                  </Col>
                  <Col md={2}>Category</Col>
                </Row>
                {this.props.ingredients.map((ingredient, index) => ingredient.heading
                ? (
                  <Draggable key={`row-${index + 1}`} draggableId={`row-${index + 1}`} index={index}>
                    {provided => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {this.getHeadingRow(ingredient, index)}
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <Draggable key={`row-${index + 1}`} draggableId={`row-${index + 1}`} index={index}>
                    {provided => (
                      <div
                        className="row"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {this.getIngredientRow(ingredient, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ display: "flex", flexDirection: "row", marginTop: '10px' }}>
          <Button small onClick={() => this.props.addIngredient(false)}>
            <img src={whitePlus} style={{ marginRight: '5px' }}/> Add ingredient
          </Button>
          <Button style={{ marginLeft: "20px" }} small outlined onClick={() => this.setState({ isAddHeadingVisible: true })}>
          <img style={{ width: '24px', height: '24px', marginRight: '5px' }}  src={blackPlus} /> Add heading
          </Button>
        </div>
        <Modal
          className="ingredients-modal"
          visible={this.state.isIngredientEditingVisible}
          close={() => this.setState({ isIngredientEditingVisible: false, ingredientModalValue: undefined, ingredientName: '', ingredientIndex: -1  })}
          buttons={[{
            onClick: () => this.setState({ isIngredientEditingVisible: false, ingredientModalValue: undefined, ingredientName: '', ingredientIndex: -1  }),
            children: 'Close',
            outlined: true,
            small: true,
            limited: true,
          },{
            onClick: this.editIngredient,
            children: 'Update',
            outlined: true,
            small: true,
            limited: true,
          }]}
        >
          <div className="rounded-container">
            <input
              onChange={this.handleIngredientEditInputChange}
              style={{ width: '100%' }}
              value={this.state.ingredientName} type="text" placeholder="Ingredient Name" />
          </div>
        </Modal>
        <Modal
          className="ingredients-modal"
          visible={this.state.isFormEditingVisible}
          close={() => this.setState({ isFormEditingVisible: false, formModalValue: undefined, formName: '', ingredientIndex: -1  })}
          buttons={[{
            onClick: () => this.setState({ isFormEditingVisible: false, formModalValue: undefined, formName: '', ingredientIndex: -1  }),
            children: 'Close',
            outlined: true,
            small: true,
            limited: true,
          },{
            onClick: this.editIngredientForm,
            children: 'Update',
            outlined: true,
            small: true,
            limited: true,
          }]}
        >
          <div className="rounded-container">
            <input
              onChange={this.handleFormEditInputChange}
              style={{ width: '100%' }}
              value={this.state.formName} type="text" placeholder="Ingredient Name" />
          </div>
        </Modal>
        <Modal
          className="ingredients-modal"
          visible={this.state.isModalVisible}
          close={this.closeModal}
          buttons={[{
            onClick: this.closeModal,
            children: 'Close',
            outlined: true,
            small: true,
            limited: true,
          },{
            onClick: this.handleIngredientChoose,
            children: 'Create',
            outlined: true,
            small: true,
            limited: true,
          }]}
        >
          <div className="rounded-container">
            <input
              onChange={this.handleInputChange}
              style={{ width: '100%' }}
              value={this.state.searchValue} type="text" placeholder="Ingredient Name" />
          </div>
        </Modal>
        <Modal
          visible={this.state.manageModalVisible}
          close={() => {this.setState({ manageModalVisible: false, ingredientIndex: -1 })}}
          buttons={[{
            children: 'Close',
            onClick: this.closeManageModal,
            limited: true,
            small: true,
            outlined: true
          }, {
            children: 'Add new',
            onClick: this.handleCreateForm,
            limited: true,
            small: true,
          }]}
        >
            <div className="rounded-container">
              <input
                onChange={this.handleFormInputChange}
                style={{ width: '100%' }}
                value={this.state.newFormName} type="text" placeholder="Form Name" />
            </div>
        </Modal>
        <Modal
          className="ingredients-modal"
          visible={this.state.isAddHeadingVisible}
          close={() => this.setState({ isAddHeadingVisible: false, headingName: '' })}
          buttons={[{
            onClick: () => this.setState({ isAddHeadingVisible: false, headingName: '' }),
            children: 'Cancel',
            outlined: true,
            small: true,
            limited: true,
          },{
            onClick: () => {
              this.props.addIngredient(true, undefined, this.state.headingName);
              this.setState({ isAddHeadingVisible: false, headingName: '' });
            },
            children: 'Save',
            outlined: false,
            small: true,
            limited: true,
          }]}
        >
            <p className="add-heading-title">Heading Name</p>
            <Input onChange={this.handleHeadingChange}
              value={this.state.headingName} type="text" placeholder="Start Typing" />
            
        </Modal>
      </React.Fragment>
    );
  }
}

export default Ingredients;
