import React from "react";
import { Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { call } from "../../api/core";

import EnumActions from "../../redux/enum/actions";
import {
  transformRecipeForState,
  validateIngredient,
} from "../../utils/recipeHelpers";
import validate from "./validation";
import Modal from "../../components/Modal";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ImageUpload from "../../components/ImageUpload";
import Input from "../Input";
import Slider from "./components/Slider";
import Nutritions from "./components/Nutritions";
import Ingredients from "./components/Ingredients";
import Instructions from "./components/Instructions";
import Notes from "./components/Notes";
import Button from "../../components/Button";
import SimilarRecipes from "./components/SimilarRecipes";
import withPermissions from "../../utils/PermissionManager";
import NoPermissions from "../../components/NoPermissions";
import { callDeleteIngredient, callEditIngredient, callGetIngredientData, callGetIngredientsList } from "../../api/recipe";
import { toast } from "react-toastify";
import plusBlack from "../../assets/images/common/plus-circle_black.png";
import DropdownWithSearch from "../../components/DropdownWithSearch";
import Counter from "../../components/Counter";
import { callDeleteIngredientForm, callEditIngredientForm } from "../../api/enum";

export const createEmptyIngredient = (heading = false, ingredientName) => {
  return {
    name: {
      name: ingredientName ?? ''
    },
    form: null,
    quantity: null,
    measure: "whole",
    displayUnit: "whole",
    shoppingList: {
      unit: "whole",
    },
    category: null,
    heading,
  };
};

class AddRecipe extends React.Component {
  state = {
    title: "",
    images: {},
    type: [],
    season: [],
    time: 15,
    allergens: [],
    calories: null,
    isPublished: false,
    carbs: {
      grams: null,
      percent: null,
    },
    protein: {
      grams: null,
      percent: null,
    },
    fat: {
      grams: null,
      percent: null,
    },
    servings: 1,
    instructions: ["", "", "", ""],
    notes: "",
    ingredients: [createEmptyIngredient()],
    similarRecipes: [],
    changedFields: [],
    success: false,
    errors: null,
    savedNutrition: {},
    isConfirmModalVisible: false,
    isDeleteIngredientModalVisible: false,
    ingredientIndexToDelete: -1,
    isDeleteIngredientFormModalVisible: false,
    ingredientFormId: undefined,
    isDeleteIngredientFromDBModalVisible: false,
    ingredientIdToDelete: -1,
    deleteIngredientTitle: '',
    ingredientNameToDelete: ''
  };

  componentDidMount() {
    if (!this.props.isFormDataLoading) {
      this.props.getFormData();
    }

    if (this.props.editing) {
      this.getRecipeForState();
    }

    if (!this.props.isListFetching) {
      this.props.getRecipeList();
    }
  }
  
  async getRecipeForState() {
    const id = this.props.match.params.id;
    const recipe = await call(`/recipe/${id}`, "GET", {});

    this.setState(transformRecipeForState(recipe.data));
  }

  changeField = (field, value) => {
    const changedFields = [...this.state.changedFields];

    if (changedFields.indexOf(field) === -1) {
      changedFields.push(field);
    }

    this.setState({
      [field]: value,
      changedFields,
    });
  };

  onTypeChange = (type) => {
    const index = this.state.type.indexOf(type.value);
    const copy = [...this.state.type];

    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(type.value);
    }

    this.changeField("type", copy);
  };

  onSeasonsChange = (season) => {
    const index = this.state.season.indexOf(season.value);
    const copy = [...this.state.season];

    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(season.value);
    }

    this.changeField("season", copy);
  };

  onAllergenChange = (allergen) => {
    const index = this.state.allergens.indexOf(allergen.value);
    const copy = [...this.state.allergens];

    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(allergen.value);
    }

    this.changeField("allergens", copy);
  };

  onNutritionChange = (ev) => {
    const data = {
      calories: this.state.calories,
      carbs: this.state.carbs.grams,
      protein: this.state.protein.grams,
      fat: this.state.fat.grams,
    };

    data[ev.target.name] = +ev.target.value || 0;

    const nutrientsSum = data.protein + data.fat + data.carbs;

    data.carbsPercent = data.carbs / nutrientsSum;
    data.proteinPercent = data.protein / nutrientsSum;
    data.fatPercent = data.fat / nutrientsSum;
    const changedFields = [...this.state.changedFields];
    if (changedFields.indexOf("nutrition") === -1) {
      changedFields.push("nutrition");
    }
    this.setState({
      changedFields,
      calories: data.calories,
      carbs: {
        grams: data.carbs,
        percent: data.carbsPercent * 100,
      },
      protein: {
        grams: data.protein,
        percent: data.proteinPercent * 100,
      },
      fat: {
        grams: data.fat,
        percent: data.fatPercent * 100,
      },
    });
  };

  getRecipe = () => {
    return {
      title: this.state.title,
      type: this.state.type,
      season: this.state.season,
      allergens: this.state.allergens,
      carbs: {
        ...this.state.carbs,
        grams: this.state.carbs.grams * this.state.servings,
      },
      image: this.state.image,
      protein: {
        ...this.state.protein,
        grams: this.state.protein.grams * this.state.servings,
      },
      fat: {
        ...this.state.fat,
        grams: this.state.fat.grams * this.state.servings,
      },
      time: this.state.time,
      servings: this.state.servings,
      calories: this.state.calories * this.state.servings,
      ingredients: this.state.ingredients.filter((i) => validateIngredient(i)),
      similarRecipes: this.state.similarRecipes.map((r) => r._id),
      instructions: this.state.instructions.filter((n) => !!n),
      notes: this.state.notes,
    };
  };
  handleSubmit = ({ isPublished = false, skipRedirect = false }) => {
    const errors = validate(this.state);

    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: null });
    }

    if (this.props.editing) {
      // debugger;
      this.props.updateRecipe(
        this.props.match.params.id,
        {
          ...this.getRecipe(),
          image: this.state.images.file,
          isPublished,
        },
        skipRedirect ? () => {} : this.onSubmitSuccess
      );
    } else {
      const recipeData = {
        ...this.getRecipe(),
        image: this.state.images.file,
        isPublished,
      };
      this.props.addRecipe(recipeData,  skipRedirect ? () => {} : this.onSubmitSuccess);
    }
  };

  onSubmitSuccess = () => {
    this.props.history.push("/recipes");
  };

  // notes
  addInstruction = () => {
    this.setState({
      instructions: [...this.state.instructions, ""],
    });
  };

  changeInstruction = (e, index) => {
    const copy = [...this.state.instructions];
    copy[index] = e.target.value;

    this.changeField("instructions", copy);
  };

  // ingredients

  addIngredient = (heading = false, ingredient, headingName) => {
    this.setState({
      ingredients: [...this.state.ingredients, ingredient ?? createEmptyIngredient(heading, headingName)],
    });
    if (ingredient) {
      this.props.setEnums({
        ingredients: [
          ...this.props.ingredients,
          ingredient
        ]
      })
    }
  };

  changeIngredient = (e, index, target) => {
    const copy = [...this.state.ingredients];

    if (e.target) {
      // debugger;
      if (
        target === "quantity" ||
        target === "displayAmount" ||
        target === "shoppingAmount"
      ) {
        if (
          e.target.value === "" ||
          /^((\.[0-9]*)|[0-9]+((\.[0-9]*)|(\/[0-9]*))?)$/.test(
            e.target.value
          ) ||
          /^[0-9]+\s?([0-9]+(\/[0-9]*)?)?$/.test(e.target.value)
        ) {
          if (target === "shoppingAmount") {
            copy[index].shoppingList = copy[index].shoppingList || {};
            copy[index].shoppingList.amount = e.target.value;
          } else {
            copy[index][target] = e.target.value;
          }
        } else {
          return;
        }
      } else if (target === "shoppingUnit") {
        copy[index].shoppingList = copy[index].shoppingList || {};
        copy[index].shoppingList.unit = e.target.value;
      } else {
        copy[index][target] = e.target.value;
      }
    } else {
      copy[index][target] = e;
    }

    this.changeField("ingredients", copy);
  };

  getNutritionForId = async (id) => {
    try {
      if (this.state.savedNutrition[id]) {
        return this.state.savedNutrition[id];
      }

      const response = await callGetIngredientData(id);

      this.setState({
        savedNutrition: {
          ...this.state.savedNutrition,
          [id]: response.data,
        },
      });

      return response.data;
    } catch (err) {
      toast.error("Get nutrition server error");
    }
  };

  openDeleteIngredientModal = (index, title) => {
    this.setState({
      isDeleteIngredientModalVisible: true,
      ingredientIndexToDelete: index,
      deleteIngredientTitle: title
    });
  }

  openDeleteIngredientFormModal = (id) => {
    this.setState({
      isDeleteIngredientFormModalVisible: true,
      ingredientFormId: id
    })
  }

  openDeleteIngredientFromDBModal = (id, name) => {
    this.setState({
      isDeleteIngredientFromDBModalVisible: true,
      ingredientIdToDelete: id,
      ingredientNameToDelete: name
    })
  }

  closeDeleteIngredientFromDBModal = () => {
    this.setState({
      isDeleteIngredientFromDBModalVisible: false,
      ingredientIdToDelete: -1,
      ingredientNameToDelete: ''
    })
  }

  closeDeleteIngredientModal = () => {
    this.setState({
      isDeleteIngredientModalVisible: false,
      ingredientIndexToDelete: -1,
      deleteIngredientTitle: '',
    })
  }

  closeDeleteIngredientFormModal = () => {
    this.setState({
      isDeleteIngredientFormModalVisible: false,
      ingredientFormId: undefined
    })
  }

  deleteIngredient = (index) => {
    this.setState({
      ingredients: this.state.ingredients.filter(
        (i, position) => position !== index
      ),
    });
    this.setState({changedFields: [...this.state.changedFields, `ingredient-${index}`] });
  };

  deleteIngredientFromDB = async (id, name) => {
    if (id) {
      this.setState({
        ingredients: this.state.ingredients.filter(
          (el) => el._id !== id
        ),
      });
      this.props.setEnums({
        ingredients: this.props.ingredients.filter(
          (el) => el._id !== id
        ),
      });
      await callDeleteIngredient(id);
    } else {
      this.setState({
        ingredients: this.state.ingredients.filter(
          (el) => el.name.name !== name
        ),
      });
      this.props.setEnums({
        ingredients: this.props.ingredients.filter(
          (el) => el.name.name !== name
        ),
      });
    }
  }

  swapUp = (index) => {
    const ingredients = this.state.ingredients;
    const tmp = ingredients[index];
    ingredients[index] = ingredients[index - 1];
    ingredients[index - 1] = tmp;
    this.setState({ ingredients }, () =>
      this.changeField("ingredients", [...ingredients])
    );
  };
  swapDown = (index) => {
    const ingredients = this.state.ingredients;
    const tmp = ingredients[index];
    ingredients[index] = ingredients[index + 1];
    ingredients[index + 1] = tmp;
    this.setState({ ingredients }, () =>
      this.changeField("ingredients", [...ingredients])
    );
  };

  handleOnDragEnd  = (result) => {
    if (!result.destination) return;
  
    const ingredients = Array.from(this.state.ingredients);
    const [reorderedItem] = ingredients.splice(result.source.index, 1);
    ingredients.splice(result.destination.index, 0, reorderedItem);
  
    this.setState({ ingredients }, () =>{
      this.changeField("ingredients", [...ingredients]);
      this.handleSubmit({isPublished: this.state.isPublished, skipRedirect: true})
    });
  }

  addEmptyIngredientForm = () => {
    this.props.setEnums({
      forms: [
        ...this.props.forms,
        {
          name: "",
          _id: "",
        },
      ],
    });
  };

  onIngredientFormCreate = async (form) => {
    const copy = [...this.props.forms, form];

    this.props.setEnums({
      forms: copy,
    });
  };

  editIngredientForm = async (id, name) => {
    const copy = [...this.props.forms];
    const index = copy.findIndex((f) => f._id === id);

    if (index !== -1) {
      copy[index].name = name;
      this.props.setEnums({
        forms: copy,
      });

      await callEditIngredientForm(id, name);
    }
  };

  editIngredient = async (id, ingredient) => {
    const copy = [...this.props.ingredients];
    const index = copy.findIndex((f) => f._id === id);

    if (index !== -1) {
      copy[index].name.name = ingredient;
      this.props.setEnums({
        ingredients: copy,
      });

      await callEditIngredient(id, {
        name: {
          name: ingredient
        }
      });
    }
  };

  deleteIngredientForm = async (id) => {
    this.props.setEnums({
      forms: this.props.forms.filter((f) => f._id !== id),
    });
    await callDeleteIngredientForm(id);
    this.props.onDeleteForm(this.props.form._id);
  };
  // todo
  render() {
    const loading = this.props.publishing || this.props.updating;
    const buttonText = this.props.editing ? "Update" : "Publish";
    const buttonSuccessText = this.props.editing ? "Updated" : "Published";
    const currentText =
      this.state.success && !loading ? buttonSuccessText : buttonText;

    const canRead = this.props.can("read", "article");
    const canEdit = this.props.can("edit", "article");
    const canCreate = this.props.can("create", "article");

    const editingMode = canRead && canEdit && this.props.editing;
    const creatingMode = canCreate && !this.props.editing;

    return (
      <Layout>
        <PageHeader
          title={`${this.props.editing ? "Edit" : "Add"} Recipe`}
          breadcrumbs="Back to Recipes"
          breadcrumbsLink="/recipes"
        />
        {editingMode || creatingMode ? (
          <>
            <Row>
              <Col md={6}>
                <ImageUpload
                  disableUpload={!this.props.can("create", "file")}
                  value={this.state.images}
                  onChange={(value) =>
                    this.changeField("images", {
                      adminPreview: value.src,
                      adminPreviewX2: value.src,
                      file: value.file,
                    })
                  }
                  isPureFile
                  id={this.props.match.params.id}
                />
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <div className="input-bold-title">
                      <Input
                        label="Title"
                        placeholder="Type here"
                        error={this.state.errors && this.state.errors.title}
                        value={this.state.title}
                        onChange={(e) => this.changeField("title", e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <DropdownWithSearch
                      multiple={true}
                      options={this.props.allergens.map((type) => ({
                        value: type._id,
                        label: type.name,
                      }))}
                      label="Allergies, Intolerance or Dislikes"
                      onChange={this.onAllergenChange}
                      defaultSelected={this.state.allergens}
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: '20px' }}>
                    <DropdownWithSearch
                      multiple={true}
                      options={this.props.types.map((type) => ({
                        value: type._id,
                        label: type.name,
                      }))}
                      label="Type of recipe"
                      onChange={this.onTypeChange}
                      error={this.state.errors && this.state.errors.type}
                      defaultSelected={this.state.type}
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: '20px' }}>
                    <DropdownWithSearch
                      multiple={true}
                      options={this.props.seasons
                        .filter((s) => s.forRecipe)
                        .map((season) => ({
                          value: season._id,
                          label: season.name,
                        }))}
                      label="Seasons"
                      onChange={this.onSeasonsChange}
                      error={this.state.errors && this.state.errors.seasons}
                      defaultSelected={this.state.season}
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: '20px' }}>
                    <Slider
                      value={this.state.time}
                      onChange={(value) => this.changeField("time", value)}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="servings-counter">
                      <p>Servings</p>
                      <Counter onChange={(value) => this.changeField("servings", value)} value={this.state.servings} />
                    </div>
                  </Col>
                  <Col md={12}>
                    <Nutritions
                      servings={this.state.servings}
                      calories={this.state.calories}
                      caloriesError={this.state.errors && this.state.errors.calories}
                      carbs={this.state.carbs}
                      carbsError={this.state.errors && this.state.errors.carbs}
                      protein={this.state.protein}
                      proteinError={this.state.errors && this.state.errors.protein}
                      fat={this.state.fat}
                      fatError={this.state.errors && this.state.errors.fat}
                      onChange={this.onNutritionChange}
                      onKcalChange={(value) => this.changeField("calories", value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <SimilarRecipes
              chips={this.state.similarRecipes}
              onChange={(value) => this.changeField("similarRecipes", value)}
            />
            <Ingredients
              onIngredientDeleted={this.openDeleteIngredientModal}
              onIngredientDeleteDB={this.openDeleteIngredientFromDBModal}
              servings={this.state.servings}
              onServingsChange={(value) => this.changeField("servings", value)}
              ingredients={this.state.ingredients}
              addIngredient={this.addIngredient}
              changeIngredient={this.changeIngredient}
              swapUp={this.swapUp}
              swapDown={this.swapDown}
              forms={this.props.forms}
              units={this.props.units}
              categories={this.props.categories}
              errors={this.state.errors && this.state.errors.ingredients}
              addEmptyIngredientForm={this.addEmptyIngredientForm}
              editIngredientForm={this.editIngredientForm}
              editIngredient={this.editIngredient}
              deleteIngredientForm={this.openDeleteIngredientFormModal}
              onIngredientFormCreate={this.onIngredientFormCreate}
              list={this.props.ingredients}
              onDragEnd={this.handleOnDragEnd}
            />
            <Row className="instructions-wrapper">
              <Col md={6}>
                <Instructions
                  instructions={this.state.instructions}
                  addInstruction={this.addInstruction}
                  changeInstruction={this.changeInstruction}
                />
              </Col>
              <Col md={6} className="notes-wrapper">
                <Notes
                  notes={this.state.notes}
                  changeNote={(value) => this.changeField("notes", value)}
                />
              </Col>
            </Row>
            {this.state.errors && (
              <div className="error-message">
                {this.state.errors.empty ||
                  "Some required information is either missing or incorrect, please correct all the highlighted errors and try again."}
              </div>
            )}
            <div className="action-buttons">
              {!this.state.isPublished && (
                <Button
                  disabled={loading}
                  small
                  className="publish-button"
                  onClick={() => this.setState({ isConfirmModalVisible: true })}
                >
                  Publish
                </Button>
              )}
              {this.props.editing && (
                <Button
                  disabled={
                    loading ||
                    (this.props.editing &&
                      this.state.changedFields.length === 0)
                  }
                  small
                  className="publish-button"
                  onClick={() =>
                    this.handleSubmit({ isPublished: this.state.isPublished })
                  }
                >
                  Update
                </Button>
              )}
              {!this.props.editing && (
                <Button
                  disabled={loading}
                  outlined
                  small
                  className="draft-button"
                  onClick={() => this.handleSubmit({ isPublished: false })}
                >
                  <img src={plusBlack} style={{ marginRight: '5px' }} /> Add To Draft
                </Button>
              )}
            </div>
            <Modal
              visible={this.state.isConfirmModalVisible}
              className="confirm-modal"
              buttons={[
                {
                  children: "Close",
                  outlined: true,
                  small: true,
                  onClick: () =>
                    this.setState({ isConfirmModalVisible: false }),
                },
                {
                  children: "Confirm",
                  small: true,
                  onClick: () => this.handleSubmit({ isPublished: true }),
                },
              ]}
            >
              <div>
                You are going to publish this recipe, do you want to proceed?
              </div>
            </Modal>
            <Modal
              visible={this.state.isDeleteIngredientFormModalVisible}
              className="confirm-modal"
              buttons={[
                {
                  children: "Close",
                  outlined: true,
                  small: true,
                  onClick: this.closeDeleteIngredientFormModal,
                },
                {
                  children: "Confirm",
                  small: true,
                  onClick: () => {
                    this.deleteIngredientForm(this.state.ingredientFormId);
                    this.closeDeleteIngredientFormModal();
                  },
                },
              ]}
            >
              <div>
                You are going to delete ingredient form, do you want to proceed?
              </div>
            </Modal>
            <Modal
              visible={this.state.isDeleteIngredientModalVisible}
              className="confirm-modal"
              buttons={[
                {
                  children: "Close",
                  outlined: true,
                  small: true,
                  onClick: this.closeDeleteIngredientModal,
                },
                {
                  children: "Confirm",
                  small: true,
                  onClick: () => {
                    this.deleteIngredient(this.state.ingredientIndexToDelete);
                    this.closeDeleteIngredientModal();
                  },
                },
              ]}
            >
              <div>
                You are going to delete {this.state.deleteIngredientTitle}, do you want to proceed?
              </div>
            </Modal>
            <Modal
              visible={this.state.isDeleteIngredientFromDBModalVisible}
              className="confirm-modal"
              buttons={[
                {
                  children: "Close",
                  outlined: true,
                  small: true,
                  onClick: this.closeDeleteIngredientFromDBModal,
                },
                {
                  children: "Confirm",
                  small: true,
                  onClick: () => {
                    this.deleteIngredientFromDB(this.state.ingredientIdToDelete, this.state.ingredientNameToDelete);
                    this.closeDeleteIngredientFromDBModal();
                  },
                },
              ]}
            >
              <div>
                You are going to delete ingredient from database, do you want to proceed?
              </div>
            </Modal>
          </>
        ) : (
          <NoPermissions />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
    allergens: state.enum.allergens,
    types: state.enum.types,
    seasons: state.enum.seasons,
    forms: state.enum.forms,
    units: state.enum.units,
    ingredients: state.enum.ingredients,
    categories: state.enum.categories,
    publishing: state.loading["ADD_RECIPE"],
    updating: state.loading["UPDATE_RECIPE"],
    isListFetching: state.loading['GET_INGREDIENTS_LIST'],
    isFormDataLoading: state.loading['GET_RECIPE_FORM_DATA']
  });

const mapDispatchToProps = (dispatch) => ({
  getFormData: () => dispatch({ type: "GET_RECIPE_FORM_DATA" }),
  addRecipe: (recipe, onSuccess) =>
    dispatch({ type: "ADD_RECIPE", recipe, onSuccess }),
  updateRecipe: (id, body, onSuccess) =>
    dispatch({ type: "UPDATE_RECIPE", id, body, onSuccess }),
  setEnums: (data) => dispatch(EnumActions.setEnums(data)),
  getRecipeList: () => dispatch({ type: 'GET_INGREDIENTS_LIST' })
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddRecipe))
);
